import {getAuthState, getMicroAppVars, hasTsgSupport} from '@sparky/framework';
import isString from 'lodash/isString';

const SparkyRegionMap: any = {
    US: new Set(['americas']),
    EU: new Set(['europe', 'de_eu', 'de', 'de_uk', 'frankfurt', 'eu_uk', 'eu_de']),
    APAC: new Set(['singapore', 'sg', 'apac_au', 'apac_jp', 'apac_in']),
    UK: new Set(['uk', 'london']),
    FED: new Set(['fed']),
    GOV: new Set(['gov']),
    IN: new Set(['in', 'india']),
    AU: new Set(['au']),
    JP: new Set(['jp'])
};

const MicroAppRegion: any = {
    US: 'US',
    EU: 'EU',
    UK: 'UK',
    APAC: 'APAC',
    FED: 'FED',
    GOV: 'GOV',
    IN: 'IN',
    AU: 'AU',
    JP: 'JP'
};

export const getApiBaseUrlFromSparky = ({microAppName}: { microAppName: string }) => {

    //TODO: uncomment this block when multi-region support is added
    // const sparkyRegion = getRegionFromSparky();
    // const microAppRegion =
    //     Object.values(MicroAppRegion).find((region: any) => SparkyRegionMap[region].has(sparkyRegion ?? '')) ?? MicroAppRegion.US;
    // const routeMapping: any = getRouteMapping(microAppName);
    // console.log({routeMapping})
    // let baseUrl = routeMapping[microAppRegion] ?? routeMapping[MicroAppRegion.US] ?? null;

    //TODO: Remove for multi-region
    let baseUrl = getServerUrl(microAppName);

    if (!baseUrl) {
        return '';
    }
    return baseUrl;
};

function getRegionFromSparky() {
    const authState = getAuthState();
    const region = authState.instances.getPlatformRegion();
    const instance = authState.instances.find((i) => i.app_id === 'ngfw_cms');
    return instance?.region ?? null;
}

function getServerUrl(microAppName: string) {
    const vars = getMicroAppVars(microAppName) ?? {};
    return vars.api_server_url ?? ''
}

function getRouteMapping(microAppName: string) {
    const vars = getMicroAppVars(microAppName) ?? {};
    const getSaseUrl = hasTsgSupport();

    const mappingVal = vars['api_routes_mapping'] ?? {};
    const mapping = isString(mappingVal) ? JSON.parse(mappingVal) : mappingVal;
    const routeMapping = Object.values(MicroAppRegion).reduce((memo: any, region: any) => {
        memo[region] = (getSaseUrl ? mapping[region]?.sase : mapping[region]?.fawkes) ?? null;

        return memo;
    }, {});

    return routeMapping;
}

export function getApiUrlPrefix({microAppName}: { microAppName: string }) {
    const vars = getMicroAppVars(microAppName) ?? {};
    const prefix = vars.api_route_prefix;

    return prefix || '/cms';
}

export function getApiVersion({microAppName}: { microAppName: string }) {
    const vars = getMicroAppVars(microAppName) ?? {};
    const apiVersion = vars.api_version ?? process.env.REACT_APP_API_VERSION;
    return apiVersion || '2024-03-15';
}
